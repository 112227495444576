import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom/client";
const TAG = "__API__";

const App = (props) => {
  useEffect(() => {
    requestPermission();
    window.location.replace("/user.html");
  }, []);
  function requestPermission() {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log(permission);
      }
    });
  }
  return <p>Dale Wheels</p>;
};
const element = document.getElementById("root");
const root = ReactDOM.createRoot(element);
root.render(<App />);
